import * as React from 'react';
import {hydrateRoot} from 'react-dom/client';

/**
 * Register components for hydration
 * @param component
 * @param componentName
 */
export const registerComponent = (component: any, componentName: string) => {
    const elements = document.querySelectorAll<HTMLDivElement>('[data-react]');
    for (const element of Array.from(elements)) {
        const name = element.dataset.react;
        if (name.indexOf(componentName + '_') === 0) {
            hydrateRoot(element, React.createElement(component, window.ReactData[name]));
        }
    }
};
